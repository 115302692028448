import { useMemo, useState } from "react";
import { Button } from "@mui/material";
import FileManager, {
  ItemView,
  Permissions,
  Toolbar,
  Item,
} from "devextreme-react/file-manager";
import { fetchWithToken } from "../../../hooks/useFetch";
import CustomFileSystemProvider from "devextreme/file_management/custom_provider";
import { useAuth } from "src/contexts/auth";
import { BusyOverlay } from "src/components/BusyOverlay";
import { TypeRun } from "../types";
import { SelectBox } from "devextreme-react";

type RunLogsProps = {
  tenantID: String;
  instrumentID: String;
  instrumentName: String;
  instrumentSerialNumber: String;
  runID?: String;
  typeRun: TypeRun;
  onClose?: () => void;
};

const bucket = "elembio-prod-usw2-s3-aviti-telemetry-logs-usw2";

const recipeTypeItems = ["Run", "_Check", "_Prime"];

const RunLogs = ({
  tenantID,
  instrumentID,
  instrumentSerialNumber,
  instrumentName,
  runID,
  typeRun,
  onClose,
}: RunLogsProps) => {
  const { getAccessTokenSilently } = useAuth();
  const [isDownloading, setIsDownloading] = useState(false);
  const [recipeType, setRecipeType] = useState("Run");

  const prefix = useMemo(() => {
    let tID = tenantID;
    if (tID === "") tID = "ten_traefikee";
    return `avitiinternallogs/${tID}/${instrumentID}_${instrumentSerialNumber}_${instrumentName}${
      runID ? `/runs/${runID}${recipeType === "Run" ? "" : recipeType}` : ""
    }`;
  }, [
    tenantID,
    instrumentID,
    instrumentName,
    instrumentSerialNumber,
    runID,
    recipeType,
  ]);

  const fileSystemProvider = useMemo(() => {
    return new CustomFileSystemProvider({
      downloadItems: (items) => {
        console.log(items);

        setIsDownloading(true);
        const paths = items.map((v) => v.path.replaceAll("//", "/"));
        const payload = {
          Paths: paths,
          Bucket: bucket,
          Prefix: prefix,
        };
        console.log("payload", payload);
        fetchWithToken(getAccessTokenSilently, "/run/download", {
          method: "POST",
          body: JSON.stringify(payload),
        }).then((blob) => {
          const B = new Blob([blob], { type: "application/octet-stream" });
          const url = window.URL.createObjectURL(B);
          const a = document.createElement("a");
          a.href = url;
          a.setAttribute("download", `archive.zip`);
          document.body.appendChild(a);
          a.click();
          a.parentNode.removeChild(a);
          window.URL.revokeObjectURL(url);
          setIsDownloading(false);
        });
      },
      getItems: (fitem) => {
        console.log(fitem);
        const req = {
          Path: fitem.path?.replaceAll("//", "/"),
          Bucket: bucket,
          Prefix: prefix,
        };
        console.log("request", req);
        return fetchWithToken(getAccessTokenSilently, "/run/logs", {
          method: "POST",
          body: JSON.stringify(req),
        });
      },
      // ...
    });
  }, [getAccessTokenSilently, prefix]);

  return (
    <div id="parentDivList" style={{ marginLeft: 30, marginRight: "auto" }}>
      <div style={{ display: "flex" }}>
        {onClose && (
          <Button
            sx={{ ml: 1, mr: 2, mb: 1 }}
            variant="contained"
            onClick={() => onClose()}
          >
            Back
          </Button>
        )}
        <div style={{ margin: 8 }}>
          s3://{bucket}/{prefix}
        </div>
        {typeRun === "Spatial" && (
          <SelectBox
            items={recipeTypeItems}
            value={recipeType}
            onValueChanged={(e) => {
              setRecipeType(e.value);
            }}
          />
        )}
      </div>
      <FileManager height="80vh" fileSystemProvider={fileSystemProvider}>
        <ItemView />
        <Permissions
          create={false}
          copy={false}
          move={false}
          delete={false}
          rename={false}
          upload={false}
          download={true}
        ></Permissions>

        <Toolbar>
          <Item name="showNavPane" visible="true" />
          <Item name="refresh" />
        </Toolbar>
      </FileManager>
      {isDownloading && <BusyOverlay />}
    </div>
  );
};

export default RunLogs;
