export const navigation = (hasExecPermission, hasCustomerAccess) => {
  console.log({ hasExecPermission, hasCustomerAccess });
  return [
    {
      text: "Instruments",
      path: "/instruments",
      icon: "box",
    },
    {
      text: "Runs",
      icon: "runner",
      items: [
        {
          text: "Sequencing Runs",
          path: "/runs",
        },
        {
          text: "Cyto Runs",
          path: "/spatialRuns",
        },
      ],
    },
    ...(!!hasCustomerAccess
      ? [
          {
            text: "Reports",
            icon: "chart",
            items: [
              {
                text: "Customer Sequencing Runs",
                path: "/customerRuns",
                icon: "globe",
              },
              {
                text: "Customer Cyto Runs",
                path: "/customerSpatialRuns",
                icon: "globe",
              },
              {
                text: "Customer Calendar",
                icon: "event",
                path: "/customer-calendar",
              },
              {
                text: "Customer Map",
                path: "/customer-map",
                icon: "map",
              },
              {
                text: "Install Report",
                path: "/install-report",
                icon: "chart",
              },
            ],
          },
        ]
      : []),

    ...(!!hasExecPermission
      ? [
          {
            text: "Executive",
            icon: "bookmark",
            items: [
              {
                text: "Customer Utilization",
                icon: "globe",
                path: "/executive-utilization",
              },
              {
                text: "Internal Utilization",
                icon: "chart",
                path: "/executive-internal-utilization",
              },
              {
                text: "Dashboard",
                icon: "fields",
                path: "/executive-dashboard",
              },
            ],
          },
        ]
      : []),
    {
      text: "Settings",
      icon: "preferences",
      items: [
        {
          text: "Profile",
          path: "/profile",
        },
      ],
    },
  ];
};
