import { Box, Typography } from "@mui/material";
import { SpatialRun } from "src/pages/runs/types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useMemo } from "react";
import { useAuth } from "src/contexts/auth";
import { fetchWithToken } from "src/hooks/useFetch";

const bucket = "elembio-prod-usw2-s3-aviti-telemetry-logs-usw2";
export default function CytoRunThumbnail({ run }: { run: SpatialRun }) {
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const { getAccessTokenSilently } = useAuth();
  const [thumbnailURLs, setThumbnailURLs] = React.useState<string[]>([]);
  const {
    tenantID,
    instrumentID,
    instrumentName,
    instrumentSerialNumber,
    runID,
  } = run || {};

  const prefix = useMemo(() => {
    let tID = tenantID;
    if (tID === "") tID = "ten_traefikee";
    return `${bucket}/avitiinternallogs/${tID}/${instrumentID}_${instrumentSerialNumber}_${instrumentName}${
      runID ? `/runs/${runID}/Thumbnails/Well` : ""
    }`;
  }, [tenantID, instrumentID, instrumentName, instrumentSerialNumber, runID]);
  const postfix = "_Thumbnail.png";

  useEffect(() => {
    run?.wells.forEach((well, i) => {
      const payload = { path: prefix + well.WellLocation + postfix };
      fetchWithToken(getAccessTokenSilently, "/thumbnailURL", {
        method: "POST",
        body: JSON.stringify(payload),
      })
        .then((res) => {
          console.log("url", res);
          setThumbnailURLs((prev) => {
            const newURLs = [...prev];
            newURLs[i] = res;
            return newURLs;
          });
        })
        .catch((error) => console.error(error));
    });
  }, [prefix]);

  const handleListItemClick = (
    _: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };
  return (
    <Box display="flex" gap={2}>
      <Box display="flex" flexDirection="column" alignItems={"center"}>
        <Typography variant="h6" fontWeight="bold" pb={2}>
          Well
        </Typography>
        <List
          sx={{
            width: "100px",
            border: "1px solid #000",
          }}
        >
          {run?.wells.map((well, i) => (
            <ListItem key={i} disablePadding>
              <ListItemButton
                selected={selectedIndex === i}
                onClick={(event) => handleListItemClick(event, i)}
              >
                <ListItemText primary={well.WellLocation} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box display="flex" flexDirection="column" alignItems={"center"}>
        <Typography variant="h6" fontWeight="bold" pb={2}>
          {run?.wells[selectedIndex]?.WellLocation}
        </Typography>
        {selectedIndex !== -1 && (
          <img src={thumbnailURLs[selectedIndex]} alt="thumbnail" />
        )}
      </Box>
    </Box>
  );
}
